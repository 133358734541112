import React, { useState, useEffect, useMemo } from "react";
import NewRequisitionConfigView from "../../Views/ProcessRelationship/NewRequisitionConfigView";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";

import {
  PROCESS_RELATIONSHIP,
  MASSIVE_LIST_ELEMENTS,
  GEOFENCE_GROUP_ELEMENTS,
  QUESTION_ENGINES_MODULES_NO_VISIT,
} from "./gql/Queries";
import LoadingCircleView from "../../Views/LoadingCircleView";
import useGlobal from "../../store";
import { CREATE_REQUISITION_CONFIG } from "./gql/Mutations";
import history from "utils/history";
import auth from "../../utils/auth";
import { useTranslation } from "react-i18next";

const NewRequisitionConfig = ({ match }) => {
  const { t } = useTranslation("common");
  const processRelationshipId = match?.params?.id ?? "";
  const [elements, setElements] = useState([]);
  const [currentProcessRelationship, setCurrentProcessRelationship] = useState({});
  
  const [session, globalActions] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const {
    data: processRelationship,
    loading: loadingProcessRelationship,
    error: errorProcessRelationship,
  } = useQuery(PROCESS_RELATIONSHIP, {
    variables: {
      _id: processRelationshipId,
    },
    fetchPolicy: "network-only",
  });

  const {
    data: questionEngineModules,
    loading: loadingQuestionEngine,
  } = useQuery(QUESTION_ENGINES_MODULES_NO_VISIT, {
    variables: {
      app: session.user ? session.appRef._id : "",
    },
    fetchPolicy: "network-only",
  });

  const [
    getMassiveListItems,
    {
      data: massiveListItems,
      loading: loadingMassiveListItems,
      error: errorMassiveListItems,
    },
  ] = useLazyQuery(MASSIVE_LIST_ELEMENTS);
  const [
    getGeofenceGroupItems,
    {
      data: geofenceGroupItems,
      loading: loadingGeofenceGroupItems,
      error: errorGeofenceGroupItems,
    },
  ] = useLazyQuery(GEOFENCE_GROUP_ELEMENTS);

  const [createRequisition] = useMutation(CREATE_REQUISITION_CONFIG, {
    onCompleted: (mutationData) => {
      history.goBack();
    },
    onError: (error) => {
        console.log(error)
    }
  });

  useEffect(() => {
    if (!loadingProcessRelationship) {
        const processRelationshipRef =
          processRelationship?.getProcessRelationship ?? {};

          setCurrentProcessRelationship(processRelationshipRef);
        if (processRelationshipRef.elementsType === "MassiveList") {
          getMassiveListItems({
            variables: {
              massiveList: processRelationshipRef.elementsList[0],
            },
          });
        } else {
          getGeofenceGroupItems({
            variables: {
              app: processRelationshipRef.app,
              groupIds: processRelationshipRef.elementsList,
            },
          });

          
        }
        
      }
  }, [loadingProcessRelationship]);

  useMemo(() => {
    if (
      session.user &&
      !auth.hasPermission("requisitionCreated", session.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: t('common.hasNotPermission'),
        color: "danger"
      });
      history.push('/analytics'); 
    } 
  }, [session]);

  if (!loadingProcessRelationship && !loadingGeofenceGroupItems && !loadingMassiveListItems && !loadingQuestionEngine) {
    
    const relations = currentProcessRelationship?.assignedUserInfo?.relations ?? [];
    let filterElements = [];

    if (currentProcessRelationship.elementsType === "MassiveList") {
        const elementList = massiveListItems?.massiveListItems ?? [];

        filterElements = elementList
          .filter((element) =>
            relations.some((relation) => relation.elementId === element._id)
          )
          .map((element) => ({ 
            _id: element._id, 
            name: element.value, 
            tags: relations.find(relation => relation.elementId === element._id)?.tags || []
          }));
    }else{
        const elementList = (geofenceGroupItems?.geofencesGroup ?? []).reduce((result, current) => {
          
            if (current?.geofences?.length) {
              return result.concat(current.geofences);
            } else {
              return result;
            }
            
         }, []);
        
        filterElements = elementList
        .filter(element => relations.some(relation => relation.elementId === element._id))
        .map(element => ({
          ...element,
          tags: relations.find(relation => relation.elementId === element._id)?.tags || []
        }));

    }

    return (
      <NewRequisitionConfigView
        elemets={filterElements}
        questionEngineModules={questionEngineModules?.questionEngineModules ?? []}
        submitSuccess={(values) => {
          const data = {
            app: session.user ? session.appRef._id : "",
            processRelationshipId: processRelationshipId,
            name: values.name,
            areaName: values.areaName,
            description: values.description,
            expirationDate: values.expirationDate,
            elementsIds: values.elementsIds,
            elementsValues: values.elementsValues,
            solutionType: values.solutionType,
            solutionModuleId: values.solutionModuleId,
            questionEngineName: values.questionEngineName,
            userCreated: session.user._id,
            expiredSwitch: values.expiredSwitch,
            reminders: values.reminders,
            notificationHour: values.notificationHour,
          }

          const images = values.images.map(image => ({
            file: image.file
          }));

          const variables = {
            data: data,
            images: images
          }

          createRequisition({
            variables: variables,
          });


        }}
        disabled={false}
      />
    );
  }else{
    return <LoadingCircleView />;
  }

  
};

export default NewRequisitionConfig;
